import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';
import { GlobalSelectors } from '@core/stateGlobal';
import { Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import { SubSink } from 'subsink';

@Directive({
  selector: '[appShowForRoles]',
  standalone: true,
})
export class ShowForRolesDirective implements OnInit, OnDestroy {
  @Input('appShowForRoles') allowedRoles: string[];
  private readonly subs = new SubSink();

  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly templateRef = inject(TemplateRef<any>);
  private readonly store = inject(Store);

  ngOnInit(): void {
    const loadingUserSession$ = this.store.select(GlobalSelectors.selectUserSession);

    this.subs.add(
      loadingUserSession$.pipe(distinctUntilChanged()).subscribe(({ groupsGraph }) => {
        if (groupsGraph) {
          this.allowedRoles.forEach((roleAllowed) => {
            if (groupsGraph.includes(roleAllowed)) {
              this.viewContainerRef.createEmbeddedView(this.templateRef);
              return;
            }
            this.viewContainerRef.clear();
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
